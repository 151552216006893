import { Injectable } from '@angular/core';

class SendInfo {
  bytesSent?: number;
  resultCode: number;
}

class ReceiveInfo {
  data: ArrayBuffer;
  socketId: number;
}
const delimiter = 0x00;

@Injectable()
export class SocketService {
  private _socket: any;
  private get socket(): any {
    if (!this._socket) {
      this._socket = (window as any).chrome.sockets.tcp;
    }
    return this._socket;
  }

  private _logsDisabled = true;

  constructor() {}

  async sendData(
    ipAddress: string,
    port: number,
    command: ArrayBuffer
  ): Promise<any> {
    if (!command) {
      return null;
    }

    const socketId = await this._createSocket();

    const connection = await this._connect(socketId, ipAddress, port);
    if (connection.result < 0) {
      return null;
    }

    return new Promise((resolve) => {
      this.socket.send(socketId, command, (sendInfo: SendInfo) => {
        this.socket.close(socketId, () => {
          this._log('SOCKET CLOSED');
          resolve(sendInfo.resultCode);
        });
      });
    });
  }

  sendDataWithResponse(
    ipAddress: string,
    port: number,
    data: ArrayBuffer,
    timeout = 60000
  ): Promise<ArrayBuffer> {
    if (!data) {
      return Promise.reject('data_not_provided');
    }

    this._log('____sendDataWithResponse_____');
    return new Promise(async (resolve, reject) => {
      let receivedData: Uint8Array[] = [];
      let dataReceived = false;

      this.socket.create({}, (createInfo: any) => {
        const socketId = createInfo.socketId;
        if (socketId > 0) {
          this.socket.connect(socketId, ipAddress, port, (result: number) => {
            this._log('_connect. result ', result);
            if (result < 0) {
              reject('connection_error');
            } else {
              this.socket.onReceive.addListener((info: ReceiveInfo) => {
                this._log('RECEIVE DATA');
                this._log('byteLength: ', info.data.byteLength);

                if (info.data.byteLength > 0 && !dataReceived) {
                  var typedArray = new Uint8Array(info.data);
                  const lastElement = typedArray[typedArray.length - 1];
                  this._log('lastElement ', lastElement);

                  if (lastElement == delimiter) {
                    dataReceived = true;
                  }

                  if (!receivedData.length) {
                    receivedData.push(typedArray);
                  } else {
                    var lastPack = receivedData[receivedData.length - 1];
                    if (lastPack.length != typedArray.length) {
                      receivedData.push(typedArray);
                    }
                  }

                  if (dataReceived) {
                    const res = [];
                    for (let pack of receivedData) {
                      for (let i = 0; i < pack.length; i++) {
                        res.push(pack[i]);
                      }
                    }

                    resolve(new Uint8Array(res).buffer);
                    this.socket.close(socketId);
                  }
                }
              });

              // this._pauseSocket(socketId);
              this.socket.send(socketId, data, (sendInfo: SendInfo) => {
                this._log('send. result');
                this._log(JSON.stringify(sendInfo));

                if (sendInfo.resultCode < 0) {
                  reject('send_data_error');
                }
              });
            }
          });

          setTimeout(() => {
            this.socket.close(socketId);
            reject('connection_timeout');
          }, timeout);
        } else {
          reject('Socket not created');
        }
      });
    });
  }

  private _pauseSocket(socketId: number, delay = 500) {
    this._log('SOCKET PAUSED');
    this.socket.setPaused(socketId, true, () => {});
    setTimeout(() => {
      this._log('SOCKET UNPAUSED');
      this.socket.setPaused(socketId, false, () => {});
    }, delay);
  }

  async checkConnection(ip: string, port: number): Promise<boolean> {
    const socketId = await this._getSocket();
    const connection = await this._connect(socketId, ip, port, 800).catch(
      () => {
        return { result: -1 };
      }
    );

    if (connection.result < 0) {
      return false;
    } else {
      return true;
    }
  }

  private async _getSocket(): Promise<number> {
    const socketsInfo = await this._getSockets();
    const freeSocket = socketsInfo.find((socket) => socket.connected);
    if (freeSocket) {
      return new Promise((resolve) => {
        this.socket.disconnect(freeSocket.socketId, () =>
          resolve(freeSocket.socketId)
        );
      });
    } else {
      return this._createSocket();
    }
  }

  private _getSockets(): Promise<any[]> {
    return new Promise((resolve) => {
      this.socket.getSockets((info: any[]) => {
        resolve(info);
      });
    });
  }

  private _connect(
    socketId: number,
    ipAddress: string,
    port: number,
    timeout: number = 0
  ): Promise<{ result: number; ipAddress: string; port: number }> {
    return new Promise((resolve, reject) => {
      if (timeout > 0) {
        var to = setTimeout(() => reject('Operation timed out.'), timeout);
      }

      this.socket.connect(socketId, ipAddress, port, (result: number) => {
        if (to) {
          clearTimeout(to);
        }

        resolve({ result, ipAddress, port });
      });
    });
  }

  private _createSocket(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.socket.create({}, (createInfo: any) => {
        const socketTcpId = createInfo.socketId;
        if (socketTcpId > 0) {
          resolve(socketTcpId);
        } else {
          reject('Socket not created');
        }
      });
    });
  }

  async disconnectAll(): Promise<any> {
    const socketsInfo = await this._getSockets();
    socketsInfo.forEach(async (socket) => {
      if (socket.connected) {
        await this._disconnect(socket.socketId);
      }
    });
  }

  private async _disconnect(socketId): Promise<any> {
    return new Promise((resolve) => {
      this.socket.disconnect(socketId, () => resolve(socketId));
    });
  }

  private _log(msg: string, ...optionalParams: any[]) {
    if (this._logsDisabled) {
      return;
    }

    console.log(msg, optionalParams);
  }
}
